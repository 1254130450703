require('normalize.css/normalize.css')
require('./main.css')
 
var qs = require('query-string');

console.log('VERSION:', new Date().toLocaleDateString(), new Date().toLocaleTimeString());

$(document).ready(function(){
  // Remove loading after all
  // images were loaded
  $('body').imagesLoaded( function() {
    $('body').removeClass('loading');
    $('body').addClass('ready')
  });
  
  // Init slick on testimonials
  // component
  $('.testimonials-iwrap').slick({
    arrows: false,
    dots: true,
  });

  // Init modal video button
  $(".video-button-secondary").modalVideo({
    channel: 'vimeo',
    vimeo: {
      autoplay: 1,
      title: false,
    }
  });

  function navigateToTelegram(e) {
	var tgUrl = "https://t.me/doqchatbot/app"
	window.open(tgUrl, "_self");
  }

  // Click on primary CTA
  $('#cta-button').on('click',navigateToTelegram)
  $('#cta-button-telegram').on('click',navigateToTelegram)

  // Click on banner play video link
  $('#banner-video-link').on('click', function(e) {
    firebase.analytics().logEvent('banner_video_play_clicked');
  })

  // Click on banner google play link
  $("#banner-google-play-link").on('click', function(e) {
    executeLink(e, 'https://play.google.com/store/apps/details?id=com.doqchat.app&hl=es_419', function() {
      firebase.analytics().logEvent('banner_google_play_link_clicked');
    })
  })

  // Click on banner app store link
  $("#banner-app-store-link").on('click', function(e) {
    executeLink(e, 'https://itunes.apple.com/ar/app/doqchat/id1468013530?mt=8', function() {
      firebase.analytics().logEvent('banner_app_store_link_clicked');
    })
  })

  // Click on banner Telegram link
   $("#banner-telegram-link").on('click', function(e) {
	// const tgUrl = "https://t.me/doqchatbot"
	const tgUrl = "https://t.me/doqchatbot/app"
	window.open(tgUrl, "_self");
    /* executeLink(e, 'https://itunes.apple.com/ar/app/doqchat/id1468013530?mt=8', function() {
      firebase.analytics().logEvent('banner_app_store_link_clicked');
    }) */
  })

  // Click on footer google play link
  $("#footer-google-play-link").on('click', function(e) {
    executeLink(e, 'https://play.google.com/store/apps/details?id=com.doqchat.app&hl=es_419', function() {
      firebase.analytics().logEvent('footer_google_play_link_clicked');
    })
  })

  // Click on banner app store link
  $("#footer-app-store-link").on('click', function(e) {
    executeLink(e, 'https://itunes.apple.com/ar/app/doqchat/id1468013530?mt=8', function() {
      firebase.analytics().logEvent('footer_app_store_link_clicked');
    })
  })

  $('#faqs-link').on('click', function() {
    firebase.analytics().logEvent('faqs_link_clicked');
  })

  $('#tutorials-link').on('click', function() {
    firebase.analytics().logEvent('tutorials_link_clicked');
  })

  $('#terms-link').on('click', function() {
    firebase.analytics().logEvent('terms_link_clicked');
  })
});

function executeLink(e, defaultLink, callback) {
  e.preventDefault();
  callback()

  var qs_data = qs.parse(window.location.search);

  var params = {
    link: 'https://doqchat.com',
    apn: 'com.doqchat.app',
    ibi: 'com.doqchat.app',
    isi: 1468013530,
    ofl: defaultLink,
  };

  params['utm_source'] = qs_data.utm_source || 'landing-direct';
  params['utm_medium'] = qs_data.utm_medium || 'landing-direct';
  params['utm_campaign'] = qs_data.utm_campaign || 'landing-direct';
  params['utm_content'] = qs_data.utm_content || 'landing-direct';
  
  if (qs_data.utm_term) {
    params['utm_term'] = qs_data.utm_term
  }

  var query = Object.keys(params)
    .map(function(k) {return k + '=' + params[k]})
    .join('&');

  window.location.href = 'https://app.doqchat.com/?' + query;
}


// function copyToClipboard(text, callback) {
//   var dummy = document.createElement("input");

//   document.body.appendChild(dummy);
  
//   dummy.value = text;
//   dummy.select();
//   dummy.setSelectionRange(0, 99999);
//   document.execCommand("copy");
//   document.body.removeChild(dummy);

//   callback();
// }